import { RootParentId } from '@biorad-lsg-tsc/organization';

export type ProjectName = string;
export type ProjectId = string;
export interface Project {
  name: ProjectName;
  id: ProjectId;
}

export const DEFAULT_PROJECT_ID = RootParentId;
export const USER_FILES = 'My BRio';
