import request from '../utils/httpUtils';
import { GATEWAY } from '../config/config';
import EntityJWTCache from '../utils/EntityJWTCache';
import { DEFAULT_PROJECT_ID, ProjectId } from '../common/project-management-types';
import { UserFile } from '../common/types';
import { getParamsWithAccessTokenHeader } from '../../../organization-management/api/access-control';

const baseURL = GATEWAY.FILEARBITER_PATHS.BASE_URL;

const jwtCache = new EntityJWTCache();

type Entity = {
  name: string;
};

type GetEntitiesQueryParams =
  | { type: string; lastSeen?: string }
  | { lastSeen?: string; exclude: string[] }
  | { lastSeen?: string; exclude: string[]; search?: string };

export function createAdditionalParamsForGetEntities(query: GetEntitiesQueryParams): any {
  let queryParams;
  if ('type' in query) {
    queryParams = { t: query.type };
  }
  if ('exclude' in query) {
    queryParams = query.exclude.length > 0 ? { exclude: JSON.stringify(query.exclude) } : {};
  }
  const { lastSeen } = query;
  if ('search' in query && query.search !== undefined) {
    queryParams = { ...queryParams, search: query.search };
  }
  if (lastSeen) {
    queryParams = { ...queryParams, lastSeen };
  }
  return {
    queryParams
  };
}

export async function getEntitiesList(query: GetEntitiesQueryParams): Promise<any> {
  const path = GATEWAY.FILEARBITER_PATHS.ENTITIES_URL;
  const additionalParams = createAdditionalParamsForGetEntities(query);
  const result = await request.get(baseURL, {}, path, additionalParams);
  jwtCache.addToken(result.data.jwt);
  return result;
}

export async function getArchivedEntitiesList(
  lastSeen?: string,
  projectId: ProjectId = DEFAULT_PROJECT_ID
): Promise<any> {
  const path = GATEWAY.FILEARBITER_PATHS.ARCHIVE_ENTITIES_URL;
  let queryParams;
  if (lastSeen) {
    queryParams = { lastSeen };
  }
  if (projectId != null && projectId !== DEFAULT_PROJECT_ID) {
    queryParams = { ...queryParams, parentId: projectId };
  }
  const additionalParams = {
    queryParams
  };

  const result = await request.get(baseURL, {}, path, additionalParams);
  jwtCache.addToken(result.data.jwt);
  return result;
}

export async function getEntity(id: string): Promise<any> {
  const pathTemplate = `${GATEWAY.FILEARBITER_PATHS.ENTITIES_URL}/${id}`;
  return request.get(baseURL, {}, pathTemplate, {});
}

export function deleteEntities(idList: Array<string>): Promise<any> {
  const pathTemplate = GATEWAY.FILEARBITER_PATHS.DELETE_ENTITIES_URL;
  return request.patch(baseURL, {}, pathTemplate, {}, idList);
}

export function archiveEntities(idList: Array<string>): Promise<any> {
  const pathTemplate = GATEWAY.FILEARBITER_PATHS.ARCHIVE_ENTITIES_URL;
  return request.patch(baseURL, {}, pathTemplate, {}, idList);
}

export function restoreEntities(idList: Array<string>): Promise<any> {
  const pathTemplate = GATEWAY.FILEARBITER_PATHS.RESTORE_ENTITIES_URL;
  return request.patch(baseURL, {}, pathTemplate, {}, idList);
}

async function fetchEntityToken(id: string): Promise<any> {
  const path = GATEWAY.FILEARBITER_PATHS.ENTITY_JWT(id);
  const result = await request.get(baseURL, {}, path, {});
  jwtCache.addToken(result.data.jwt);
  return result;
}

export async function getEntityJWT(id: string): Promise<any> {
  let token = jwtCache.getToken(id);
  if (!token) {
    const result = await fetchEntityToken(id);
    token = result.data.jwt;
  }
  return token;
}

export async function putEntity(
  id: string,
  entity: Entity,
  projectAccessToken?: string
): Promise<any> {
  const pathTemplate = `${GATEWAY.FILEARBITER_PATHS.ENTITIES_URL}/${id}`;
  const params = getParamsWithAccessTokenHeader(projectAccessToken);
  return request.put(baseURL, {}, pathTemplate, params, entity);
}

export async function moveEntity(entity: UserFile, projectId: string): Promise<any> {
  const pathTemplate = `${GATEWAY.FILEARBITER_PATHS.ENTITIES_URL}/move-to-project/${entity.id}`;
  const body = { parentId: projectId, versionNumber: entity.versionNumber };
  const result = await request.put(baseURL, {}, pathTemplate, {}, body);
  return result.data;
}

export async function getEntityDownloadUrl(id: string): Promise<any> {
  const path = GATEWAY.FILEARBITER_PATHS.GET_DOWNLOAD_URL(id);
  return request.get(baseURL, {}, path, {});
}

export async function getEntityInfo(entityId: string): Promise<any> {
  const path = GATEWAY.FILEARBITER_PATHS.GET_ENTITY_INFO(entityId);
  return request.get(baseURL, {}, path, {});
}

export async function getProjectEntitiesList(
  projectId: string,
  query: GetEntitiesQueryParams
): Promise<any> {
  const path = GATEWAY.FILEARBITER_PATHS.PROJECTS_URL(projectId);
  const additionalParams = createAdditionalParamsForGetEntities(query);
  const result = await request.get(baseURL, {}, path, additionalParams);
  return result.data;
}

export async function getEntitiesOfProject(query: any, projectId: ProjectId) {
  let payload;
  if (projectId === DEFAULT_PROJECT_ID) {
    const result = await getEntitiesList(query);
    payload = result.data;
  } else {
    payload = await getProjectEntitiesList(projectId, query);
  }
  return payload;
}
