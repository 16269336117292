import { fromJS, Map } from 'immutable';
import { ProjectPermissions, UserPermissions } from '@biorad-lsg-tsc/organization';
import { ReduxState } from '../../types';
import {
  DEFAULT_PROJECT_ID,
  ProjectId
} from '../../frontend-common-libs/src/common/project-management-types';
import { NullableBoolean } from '../../frontend-common-libs/src/common/nullable-types';
import { getActiveOrg } from '../../organization-management/selectors/selectors';

export function getIsProjectsLoading({ projects }: ReduxState, orgId: string): NullableBoolean {
  return projects.getIn(['projects', orgId, 'loading']) as NullableBoolean;
}

export function isProjectsLoaded({ projects }: ReduxState, orgId: string): boolean {
  return projects.getIn(['projects', orgId, 'projects']) != null;
}

export function getProjects({ projects }: ReduxState, orgId: string): Map<string, any> {
  return (projects.getIn(['projects', orgId, 'projects']) as Map<string, any>) || fromJS([]);
}

export function getSelectedProjectId({ projects }: ReduxState): ProjectId {
  if (projects == null) return DEFAULT_PROJECT_ID;
  return projects.get('selectedProjectId') || DEFAULT_PROJECT_ID;
}

export function getSelectedProjectName(state: ReduxState): string | undefined {
  const selectedProjectId = getSelectedProjectId(state);
  if (selectedProjectId === DEFAULT_PROJECT_ID) return undefined;
  const orgId = getActiveOrg(state);
  const projects = getProjects(state, orgId);
  const selectedProject = projects.find(proj => proj.get('id') === selectedProjectId);
  if (selectedProject) return selectedProject.get('name');
  return undefined;
}

export function getIsProjectDetailsLoading(
  { projects }: ReduxState,
  projectId: ProjectId
): NullableBoolean {
  return projects.getIn(['details', projectId, 'loading']) as NullableBoolean;
}

export function isProjectDetailsLoaded({ projects }: ReduxState, projectId: ProjectId): boolean {
  return projects.getIn(['details', projectId, 'details']) != null;
}

export function getProjectDetails(
  { projects }: ReduxState,
  projectId: ProjectId
): Map<string, any> {
  return projects.getIn(['details', projectId, 'details']) as Map<string, any>;
}

export function isProjectTokenLoading({ projects }: ReduxState, projectId: ProjectId): boolean {
  return projects.getIn(['details', projectId, 'loadingProjectAccessToken']) === true;
}

export function getProjectUserToken(
  { projects }: ReduxState,
  projectId: ProjectId
): string | undefined {
  return projects.getIn(['details', projectId, 'userToken', 'accessToken']) as string | undefined;
}

export function getProjectRefreshToken(
  { projects }: ReduxState,
  projectId: ProjectId
): string | undefined {
  return projects.getIn(['details', projectId, 'userToken', 'refreshToken']) as string | undefined;
}

export function getProjectUserPermissions(
  { projects }: ReduxState,
  projectId: ProjectId
): UserPermissions | undefined {
  return projects.getIn(['details', projectId, 'userPermissions']) as UserPermissions;
}

export function hasProjectPermissions(
  state: ReduxState,
  projectId: ProjectId,
  permissions: string[]
): boolean {
  if (projectId === DEFAULT_PROJECT_ID) return true;
  const projectUserPermissions = getProjectUserPermissions(state, projectId);
  if (projectUserPermissions == null) return false;
  return projectUserPermissions.hasPermissions(permissions);
}

export function canEditFilesInProject(state: ReduxState, projectId: ProjectId): boolean {
  return hasProjectPermissions(state, projectId, [ProjectPermissions.EditFiles]);
}

export function getProjectOrgIdIfLoaded(
  { projects }: ReduxState,
  projectId: ProjectId
): string | undefined {
  let foundOrgId;
  const allProjects = projects.get('projects') as Map<string, any>;
  if (!allProjects) return undefined;
  allProjects.entrySeq().forEach(([orgId, orgDetails]) => {
    const orgProjects = orgDetails.get('projects') as Map<string, any>;
    if (orgProjects) {
      const project = orgProjects.find(proj => proj.get('id') === projectId);
      if (project) {
        foundOrgId = orgId;
      }
    }
  });
  return foundOrgId;
}

export function canMoveFileToProject(state: ReduxState): boolean {
  if (getSelectedProjectId(state) !== DEFAULT_PROJECT_ID) return false;
  const orgId = getActiveOrg(state);
  const projects = getProjects(state, orgId);
  if (projects == null || projects.size <= 0) return false;
  return true;
}

export function getOrgName({ projects }: ReduxState, orgId: string): string | undefined {
  return projects.getIn(['projects', orgId, 'orgName']) as string | undefined;
}

export function getActiveOrgName(state: ReduxState): string | undefined {
  const orgId = getActiveOrg(state);
  return getOrgName(state, orgId);
}
