import React from 'react';
import { RouteComponentProps } from 'react-router';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import ErrorAlert from '../../../frontend-common-libs/src/components/common/ErrorAlert';
import Loader from '../../../frontend-common-libs/src/components/common/Loader';
import RunRepository from '../repository/RunRepository';
import './styles/run-page.scss';
import './styles/run-report-page.scss';
import RunParser from './report/run-report/sections-parsing/RunParser';
import RunDetails from './details/RunDetails';
import RunFooterNavBar from './RunFooterNavBar';
import RunProtocol from './protocol/RunProtocol';
import RunEdits from './details/RunEdits';
import NavigationBlockPrompt from '../../../frontend-common-libs/src/components/common/NavigationBlockPrompt';
import { pcrPageRegex } from '../../../frontend-common-libs/src/common/routes/regex';
import { ReduxState } from '../../../types';
import { getSelectedProjectId } from '../../../project-management';
import { canEditFilesInProject } from '../../../project-management/selectors/selectors';
import { updateRun as updateRunAction } from '../actions/run-actions';
import SetSelectedProjectEvent from '../../../frontend-common-libs/src/system-event/project-management/SetSelectedProjectEvent';

type State = {
  error: string;
  isLoading: boolean;
  runParser: RunParser;
  runEdits: RunEdits;
};

export type Props = {
  match: RouteComponentProps['match'];
  history?: RouteComponentProps['history'];
  canEditProjectFiles?: boolean;
  updateRun?: (entityId: string, body: any) => any;
};

export class RunPageImpl extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      error: '',
      isLoading: true,
      // @ts-ignore
      runParser: {}
    };
  }

  async componentDidMount() {
    await this.loadRun();
  }

  componentWillUnmount() {
    const { runEdits } = this.state;
    if (runEdits == null) return;
    runEdits.unregisterStateChanged(this.editsChanged);
  }

  get entityId() {
    const { match } = this.props as Record<string, any>;
    const { entityId } = match.params;
    return entityId;
  }

  loadRun = async () => {
    try {
      const ptcRun = await RunRepository.instance.getRun(this.entityId);
      const runParser = new RunParser(ptcRun.runReport.run);
      const runEdits = new RunEdits(ptcRun.file.name, ptcRun.runInfo.plateId, ptcRun.runInfo.notes);
      runEdits.registerStateChanged(this.editsChanged);
      this.setState({
        isLoading: false,
        runParser,
        runEdits
      });
      SetSelectedProjectEvent.notify(ptcRun.file.parent_id);
    } catch (e) {
      const errorMessage = 'Failed to load run report';
      this.setState({
        error: errorMessage,
        isLoading: false
      });
    }
  };

  editsChanged = (runEdits: RunEdits) => {
    this.setState({ runEdits });
  };

  renderNamePanel() {
    const { runEdits } = this.state;
    return (
      <div className="buttons-panel">
        <span id="ptc-run-name" className="ptc-run-name">
          {runEdits.originalFileName}
        </span>
      </div>
    );
  }

  renderRunDetails = () => {
    const { runParser, runEdits } = this.state;
    const { canEditProjectFiles, updateRun } = this.props;
    return (
      <RunDetails
        entityId={this.entityId}
        runEdits={runEdits}
        runParser={runParser}
        updateRun={updateRun}
        disabled={!canEditProjectFiles}
      />
    );
  };

  renderProtocol = () => {
    const { runParser } = this.state;
    return <RunProtocol runParser={runParser} />;
  };

  pcrRoutes = [
    { route: 'details', render: this.renderRunDetails },
    { route: 'protocol', render: this.renderProtocol }
  ];

  getDefaultRedirect = () => {
    const { match } = this.props;
    const { path } = match;
    return `${path}/details`;
  };

  // eslint-disable-next-line react/require-render-return
  render() {
    const { isLoading, error, runEdits } = this.state;
    if (isLoading) {
      return <Loader />;
    }
    if (error) return <ErrorAlert error={error} errorId="render-run-error" />;
    const { match } = this.props;

    return (
      <>
        <NavigationBlockPrompt
          shouldBlock={runEdits.hasEdits()}
          whiteListURLPattern={new RegExp(pcrPageRegex)}
          confirmationMessage="Your PTC run contains unsaved changes. Are you sure you want to leave?"
          title="Discard changes?"
          okBtnText="Leave"
        />
        <div className="run-page">
          {this.renderNamePanel()}
          <div className="run-page-content">
            <Switch>
              {this.pcrRoutes.map(({ route, render }) => (
                <Route key={route} exact path={`${match.path}/${route}`} render={render} />
              ))}
              <Redirect to={this.getDefaultRedirect()} />
            </Switch>
          </div>
          <RunFooterNavBar match={match} />
        </div>
      </>
    );
  }
}

export function mapStateToProps(state: ReduxState) {
  const projectId = getSelectedProjectId(state);
  return { canEditProjectFiles: canEditFilesInProject(state, projectId) };
}

export default withRouter(
  // @ts-ignore
  connect(mapStateToProps, { updateRun: updateRunAction })(RunPageImpl)
);
