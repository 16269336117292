import React from 'react';
import { connect } from 'react-redux';
import { ManageProtocols } from '../../frontend-common-libs/src/components/pcr/ManageProtocols';
import conventionalPcrRoutes from '../routes';
import coreRoutes from '../../core/routes';
import { ProjectId } from '../../frontend-common-libs/src/common/project-management-types';
import ProjectProtocols from './repository/ProjectProtocols';
import {
  archiveProtocols,
  exportPrcl,
  getProtocolsIfNeeded,
  renameProtocol as renameProtocolAction,
  uploadProtocols
} from './actions/protocol-actions';
import ProtocolListRepository from './repository/ProtocolListRepository';
import UploadHandler from '../../frontend-common-libs/src/file-operations/upload-files/UploadHandler';
import UploadHandlerRepository from '../../frontend-common-libs/src/file-operations/upload-files/UploadHandlerRepository';
import { ConnectViewModel } from '../../frontend-common-libs/src/common/mvvm/ConnectViewModel';
import { ReduxState } from '../../types';
import { getSelectedProjectId } from '../../project-management';
import { canEditFilesInProject } from '../../project-management/selectors/selectors';

export type Props = {
  selectedProjectId: ProjectId;
  selectedProjectName?: string;
  projectProtocols: ProjectProtocols;
  canEditProjectFiles?: boolean;
  renameProtocol?: (projectId: ProjectId, entityId: string, newName: string) => any;
};

export class ProtocolListPageImpl extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    UploadHandlerRepository.instance.addHandler(
      new UploadHandler(
        `${coreRoutes.APP}${conventionalPcrRoutes.PCR_PROTOCOL_LIST}`,
        uploadProtocols,
        'Upload PTC Protocols',
        '.prcl'
      )
    );
  }

  getProtocolsIfNeeded = async (lastSeen: string): Promise<void> => {
    const { selectedProjectId } = this.props;
    await getProtocolsIfNeeded(selectedProjectId, lastSeen);
  };

  renameProtocol = async (entityId: string, newName: string): Promise<void> => {
    const { selectedProjectId, renameProtocol } = this.props;
    if (!renameProtocol) return;
    await renameProtocol(selectedProjectId, entityId, newName);
  };

  archiveProtocols = async (entityIdList: string[]): Promise<void> => {
    const { selectedProjectId } = this.props;
    await archiveProtocols(selectedProjectId, entityIdList);
  };

  render() {
    const { projectProtocols, selectedProjectName, canEditProjectFiles } = this.props;
    const { isLoading, protocols, lastSeen, errorMessage, staleData, projectId } = projectProtocols;

    const manageProtocolsProps = {
      isLoading,
      protocols: protocols.valueSeq(),
      lastSeen,
      errorMessage,
      getProtocolsIfNeeded: this.getProtocolsIfNeeded,
      staleData,
      projectId,
      projectName: selectedProjectName,
      loaded: true,
      createProtocolRoute: `${coreRoutes.APP}${conventionalPcrRoutes.CREATE_PCR_PROTOCOL}`,
      openProtocolRoute: `${coreRoutes.APP}${conventionalPcrRoutes.PCR_PROTOCOL}`,
      renameProtocol: this.renameProtocol,
      archiveProtocols: this.archiveProtocols,
      exportProtocol: exportPrcl,
      uploadProtocols,
      canEditProjectFiles
    };
    return <ManageProtocols {...manageProtocolsProps} />;
  }
}

export function ProtocolListPage(props: Readonly<Props>) {
  const { selectedProjectId } = props;
  const vm = ProtocolListRepository.instance;
  return (
    <ConnectViewModel vm={vm} vmPropKey="protocolListRepository">
      <ProtocolListPageImpl
        {...{ ...props, projectProtocols: vm.getProjectProtocols(selectedProjectId) }}
      />
    </ConnectViewModel>
  );
}

export function mapStateToProps(state: ReduxState) {
  const projectId = getSelectedProjectId(state);
  return {
    canEditProjectFiles: canEditFilesInProject(state, projectId)
  };
}

export default connect(mapStateToProps, { renameProtocol: renameProtocolAction })(ProtocolListPage);
