import React, { Component, ComponentType } from 'react';
import { Map, Seq } from 'immutable';
import { Redirect } from 'react-router';
import createProtocolIcon from '../../img/icons-add-icon.svg';
import ProtocolTable, { Props as ProtocolTableProps } from './ProtocolTable';
import { ProjectId } from '../../common/project-management-types';
import Loader from '../common/Loader';
import withDropZone, { Props as WithDropZoneProps } from '../files/WithDropZone';
import { ContextBar } from '../common/context-bar/ContextBar';
import UploadFilesButton from '../common/upload-files/UploadFilesButton';
import { UserFile } from '../../common/types';
import FileHistoryVm from '../files/file-history/FileHistoryVm';
import { FileHistory } from '../files/file-history/FileHistory';

export type ManageProtocolsProps = {
  isLoading: boolean;
  protocols: Seq.Indexed<Map<string, any>>;
  lastSeen: string;
  errorMessage: string;
  getProtocolsIfNeeded: (lastSeen: string) => any;
  staleData: boolean;
  projectId: ProjectId;
  projectName?: string;
  loaded: boolean;
  createProtocolRoute: string;
  openProtocolRoute: string;
  archiveProtocols: (entityIdList: string[]) => Promise<void>;
  renameProtocol: (entityId: string, newName: string) => Promise<void>;
  exportProtocol?: (fileEntity: any) => Promise<void>;
  uploadProtocols?: (files: File[], projectId: ProjectId) => Promise<any>;
  moveFile?: (entity: UserFile) => unknown;
  canEditProjectFiles?: boolean;
};

export type ManageProtocolsState = {
  addNew: boolean;
  fileHistoryVm: FileHistoryVm;
};

export class ManageProtocols extends Component<ManageProtocolsProps, ManageProtocolsState> {
  DroppableFileTable: ComponentType<ProtocolTableProps & WithDropZoneProps>;

  constructor(props: ManageProtocolsProps) {
    super(props);
    this.state = { addNew: false, fileHistoryVm: new FileHistoryVm() };
    this.DroppableFileTable = withDropZone<ProtocolTableProps>(ProtocolTable);
  }

  componentDidMount() {
    const { protocols, staleData } = this.props;
    if (!protocols.size || staleData) this.fetchMore();
  }

  componentDidUpdate() {
    const { errorMessage, isLoading, staleData } = this.props;
    if (errorMessage === '' && !isLoading && staleData) this.fetchMore();
  }

  fetchMore = () => {
    const { getProtocolsIfNeeded, lastSeen } = this.props;
    getProtocolsIfNeeded(lastSeen);
  };

  addProtocol = () => {
    this.setState({ addNew: true });
  };

  uploadFiles = async (files: File[]): Promise<void> => {
    const { projectId, uploadProtocols } = this.props;
    if (uploadProtocols == null) return;
    await uploadProtocols(files, projectId);
  };

  shouldShowEmpty() {
    const { isLoading, errorMessage, protocols } = this.props;
    return !isLoading && !errorMessage && protocols.size === 0;
  }

  showFileHistory = (file: UserFile) => {
    const { fileHistoryVm } = this.state;
    fileHistoryVm.showFileHistoryDialog(file);
  };

  renderFileHistory = () => {
    const { fileHistoryVm } = this.state;
    return <FileHistory fileHistoryVm={fileHistoryVm} />;
  };

  renderProtocolTable() {
    const {
      isLoading,
      protocols,
      lastSeen,
      errorMessage,
      openProtocolRoute,
      renameProtocol,
      archiveProtocols,
      exportProtocol,
      uploadProtocols,
      moveFile,
      canEditProjectFiles
    } = this.props;
    const protocolTableProps = {
      protocols,
      fetchMoreData: this.fetchMore,
      hasMoreData: !!lastSeen,
      isLoading,
      isError: !!errorMessage,
      openProtocolRoute,
      renameProtocol,
      archiveProtocols,
      exportProtocol,
      moveFile,
      fileHistory: this.showFileHistory,
      canEditProjectFiles
    };
    if (uploadProtocols != null) {
      const { DroppableFileTable } = this;

      return (
        <DroppableFileTable
          {...protocolTableProps}
          showEmpty={this.shouldShowEmpty()}
          uploadFiles={this.uploadFiles}
          className="flex-column-container"
        />
      );
    }

    return <ProtocolTable {...protocolTableProps} />;
  }

  render() {
    const { addNew } = this.state;
    const {
      isLoading,
      staleData,
      createProtocolRoute,
      projectId,
      projectName,
      canEditProjectFiles
    } = this.props;
    if (addNew) {
      return <Redirect to={createProtocolRoute} push />;
    }
    if (isLoading && staleData) {
      return <Loader />;
    }

    return (
      <>
        <ContextBar
          id="breadcrumb-protocols"
          titleId="my-protocols"
          title="Protocols"
          projectName={projectName}
        >
          <>
            <UploadFilesButton selectedProjectId={projectId} />
            <div className="protocols-buttons">
              <button
                type="button"
                onClick={this.addProtocol}
                id="create-protocol-btn"
                className="create-button hover-image-button"
                disabled={!canEditProjectFiles}
              >
                <img src={createProtocolIcon} alt="+" />
                <span className="create-text">Create Protocol</span>
              </button>
            </div>
          </>
        </ContextBar>
        {this.renderProtocolTable()}
        {this.renderFileHistory()}
      </>
    );
  }
}
