import { Map } from 'immutable';
import { CPCR_PROTOCOL_ENTITY_TYPE } from '../../../frontend-common-libs/src/conventional-pcr/protocol';
import {
  archiveEntities,
  getEntitiesOfProject,
  putEntity
} from '../../../frontend-common-libs/src/api/entities';
import { creatCpcrProtocol, editCpcrProtocol, getCpcrProtocol } from '../api/protocols';
import { ProjectId } from '../../../frontend-common-libs/src/common/project-management-types';
import ProtocolListRepository from '../repository/ProtocolListRepository';
import QPcrProtocol from '../../../frontend-common-libs/src/components/pcr/pcr-protocols/models/QPcrProtocol';
import { PcrProtocolContent } from '../../../frontend-common-libs/src/components/pcr/pcr_protocol_types';
import { downloadPrclFile } from '../../../frontend-common-libs/src/components/pcr/export-protocol/export-prcl';
import FileOperationEvent from '../../../frontend-common-libs/src/file-operations/FileOperationEvent';
import {
  EXPORT_FILE_COMPLETE,
  EXPORT_FILE_FAILED,
  EXPORT_FILE_STARTED
} from '../../../frontend-common-libs/src/file-operations/file_operation_types';
import { getNextUploadId } from '../../../frontend-common-libs/src/file-operations/utils';
import PcrProtocolUpload from '../upload/PcrProtocolUpload';
import { isNetworkError } from '../../../frontend-common-libs/src/utils/errorUtils';
import { DownloadNetworkError } from '../../../frontend-common-libs/src/file-operations/messages';
import {
  TrackedFilesEvents,
  trackEvent
} from '../../../frontend-common-libs/src/user-analytics/trackedEvents';
import {
  CbWithToken,
  DispatchType
} from '../../../organization-management/actions/WithRefreshToken';
import { withRefreshProjectToken } from '../../../project-management/actions/with-refresh-project-token';
import { GetState } from '../../../types';
import ProtocolRepository from '../repository/ProtocolRepository';

export async function getProtocolsIfNeeded(projectId: ProjectId, lastSeen: string) {
  const projectProtocols = ProtocolListRepository.instance.getProjectProtocols(projectId);
  const { isLoading } = projectProtocols;
  if (isLoading) return;
  try {
    projectProtocols.setLoading();
    const query = {
      type: CPCR_PROTOCOL_ENTITY_TYPE,
      lastSeen
    };
    const payload = await getEntitiesOfProject(query, projectId);
    projectProtocols.updateProtocolsList(payload);
  } catch (e) {
    projectProtocols.setError(e instanceof Error ? e.message : 'Unknown error');
  }
}

export function renameProtocol(projectId: ProjectId, entityId: string, newName: string) {
  return async (dispatch: DispatchType, getState: GetState) => {
    const renameCb: CbWithToken = async (projectAccessToken: string) => {
      return putEntity(entityId, { name: newName }, projectAccessToken);
    };
    const putEntityRes = await withRefreshProjectToken(dispatch, getState, projectId, renameCb);
    const renamedFile = putEntityRes.data;
    const projectProtocols = ProtocolListRepository.instance.getProjectProtocols(projectId);
    projectProtocols.protocolUpdated(renamedFile);
  };
}

export async function archiveProtocols(projectId: ProjectId, entityIdList: string[]) {
  const res = await archiveEntities(entityIdList);
  const projectProtocols = ProtocolListRepository.instance.getProjectProtocols(projectId);
  projectProtocols.protocolsArchived(entityIdList);
  return res;
}

export function createProtocol(pcrProtocol: QPcrProtocol) {
  return async (dispatch: DispatchType, getState: GetState) => {
    const { projectId } = pcrProtocol;
    trackEvent(TrackedFilesEvents.PtcCreateProtocol, { fileName: pcrProtocol.name });
    const renameCb: CbWithToken = async (projectAccessToken: string) => {
      return creatCpcrProtocol(
        pcrProtocol.name,
        pcrProtocol.protocol.toJS() as PcrProtocolContent,
        projectId,
        projectAccessToken
      );
    };
    return withRefreshProjectToken(dispatch, getState, projectId, renameCb);
  };
}

export function editProtocol(pcrProtocol: QPcrProtocol) {
  return async (dispatch: DispatchType, getState: GetState) => {
    const protocol = pcrProtocol.protocol.toJS() as PcrProtocolContent;
    const { projectId } = pcrProtocol;
    const editCb: CbWithToken = async (projectAccessToken: string) => {
      return editCpcrProtocol(
        pcrProtocol.templateId,
        pcrProtocol.name,
        protocol,
        pcrProtocol.versionNumber,
        projectAccessToken
      );
    };
    const file = await withRefreshProjectToken(dispatch, getState, projectId, editCb);
    ProtocolRepository.instance.protocolSaved({ file, protocol });
    ProtocolListRepository.instance.getProjectProtocols(file.parent_id).protocolUpdated(file);
    trackEvent(TrackedFilesEvents.PtcEditProtocol, { fileName: pcrProtocol.name });
  };
}

export async function exportPrcl(fileEntitiy: Map<string, any>) {
  const id = getNextUploadId();
  try {
    const entityId = fileEntitiy.get('id');
    const fileName = fileEntitiy.get('name');
    FileOperationEvent.notify(EXPORT_FILE_STARTED, { id, fileName });
    const rundef = await getCpcrProtocol(entityId, true);
    downloadPrclFile(fileName, rundef);
    FileOperationEvent.notify(EXPORT_FILE_COMPLETE, { id });
    trackEvent(TrackedFilesEvents.PtcExportProtocol, { fileName });
  } catch (ex) {
    if (isNetworkError(ex as Error)) {
      FileOperationEvent.notify(EXPORT_FILE_FAILED, {
        id,
        errorMessage: DownloadNetworkError
      });
      return;
    }
    FileOperationEvent.notify(EXPORT_FILE_FAILED, { id });
  }
}

export async function uploadProtocols(files: File[], projectId: ProjectId) {
  for (let i = 0; i < files.length; i += 1) {
    const protocolUpload = new PcrProtocolUpload(files[i], projectId);
    protocolUpload.start();
  }
}
