import React from 'react';
import { connect } from 'react-redux';
import {
  InstrumentFamilyPluginRepository,
  InstrumentFamilyPlugin
} from '../../instrument-family-plugin';
import CreateExperimentCard from '../../frontend-common-libs/src/components/create-experiment/CreateExperimentCard';
import { ReduxState } from '../../types';
import { getSelectedProjectId } from '../../project-management';
import { canEditFilesInProject } from '../../project-management/selectors/selectors';

export type Props = {
  hasEditPermissions?: boolean;
};

export function CreateExperimentImpl(props: Readonly<Props>) {
  const { hasEditPermissions } = props;
  return (
    <div className="create-experiment-page">
      <span id="create-experiment-header" className="create-experiment-header">
        Define Your Experiment
      </span>
      {InstrumentFamilyPluginRepository.getInstance()
        .getAll()
        .map((plugin: InstrumentFamilyPlugin) => {
          const { createExperimentCard, name } = plugin;

          if (!createExperimentCard) {
            return null;
          }

          const {
            title,
            primaryLinks,
            secondaryLinks,
            instrumentThumbnail: instrumentThumbnailRelativePath
          } = createExperimentCard;

          const instrumentThumbnailAbsolutePath = plugin.createInstrumentThumbnailUrl(
            instrumentThumbnailRelativePath
          );

          return (
            <CreateExperimentCard
              id={`${name}-card`}
              key={name}
              img={instrumentThumbnailAbsolutePath}
              header={title}
              primaryLinks={primaryLinks}
              secondaryLinks={secondaryLinks}
              hasEditPermissions={hasEditPermissions}
            />
          );
        })}
    </div>
  );
}

export function mapStateToProps(state: ReduxState): {
  [key: string]: any;
} {
  const projectId = getSelectedProjectId(state);
  const hasEditPermissions = canEditFilesInProject(state, projectId);
  return { hasEditPermissions };
}

export default connect(mapStateToProps, {})(CreateExperimentImpl);
